'use client'

import { news } from '@store/index'
import { useRecoilState } from 'recoil'
import FullPopup from '@component/fullPopup'

export default function FixedCategory({
  children
}) {

  const [{ showFiexdCategory }, setNewsState] = useRecoilState(news)
  function closeHandle() {
    setNewsState(false)
  }

  return (
    <FullPopup left closeable show={showFiexdCategory} onClose={closeHandle} contentClassName="w-full sm:w-auto">
      {
        children
      }
    </FullPopup>
  )
}