'use client'

import useProgressRouter from '@hooks/useProgressRouter'
import { URLS, getRouterHref } from '@router'
import { news } from '@store/index'
import { useState } from 'react'
import { useRecoilState } from 'recoil'

export default function Search() {
  const [keywords, setKeywords] = useState('')

  const [, setNewsState] = useRecoilState(news)
  function closeHandle() {
    setNewsState(false)
  }


  const { pushRouter } = useProgressRouter()
  function submitHandle(event) {
    event.preventDefault()
    const url = getRouterHref(URLS.newsSearchList, { keywords })
    pushRouter(url)
    closeHandle()
  }

  return (
    <form className='w-full h-32 bg-neutral-1 rounded-sm items-center pl-12 flex' onSubmit={submitHandle}>
      <input type='text' placeholder='Search' className='bg-transparent text-sm h-full flex-1' autoFocus value={keywords} onChange={e => setKeywords(e.target.value)} />
      <button type='submit' className='px-12' aria-label='search news'>
        <i className='icon icon-search text-black'></i>
      </button>
    </form>
  )
}