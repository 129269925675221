'use client'

import Collapse from '@component/collapse'
import { articleDataLayer } from '@plugin/google/gtm/modules/news'
import getRouter, { URLS } from '@router'
import { news } from '@store/index'
import classNames from 'classnames'
import { map } from 'lodash-es'
import DisablePrefetchLink from '@component/disablePrefetchLink'
import { useCallback, useState } from 'react'
import { useSetRecoilState } from 'recoil'

export default function Item({
  data
}) {

  let href
  let parent = false
  if (data.child && data.child.length) {
    parent = true
  } else if (data.link) {
    href = data.link
  } else if (data.hubPageSlug) {
    href = getRouter(URLS.newsHubpage, { hubSlug: data.hubPageSlug })
  }

  const setNewsState = useSetRecoilState(news)
  const clickHandle = useCallback(() => {
    articleDataLayer.categoryClick(data)
    setNewsState({ showFiexdCategory: false })
  }, [data, setNewsState])

  return (
    <div className='font-normal' onClick={clickHandle}>
      {
        parent
          ? (<ParentItem data={data}></ParentItem>)
          : (
            href
              ? <DisablePrefetchLink href={href}>{data.name}</DisablePrefetchLink>
              : <span>{data.name}</span>
          )
      }

    </div>
  )
}

export function ParentItem({
  data
}) {

  const [show, setShow] = useState(false)

  function toggleHandle() {
    setShow(!show)
  }

  return (
    <>
      <div onClick={toggleHandle} className='cursor-pointer'>{data.name}<i className={classNames('icon font-bold ml-4', { 'icon-arrow-down': show, 'icon-arrow-right': !show })}></i></div>
      <Collapse show={show} contentClassName='space-y-16 py-16'>{
        map(data.child, (item, idx) => (
          <div key={idx} className='flex space-x-20'>
            <span className='w-2 h-24 bg-neutral'></span>
            <Item data={item}></Item>
          </div>
        ))
      }</Collapse>
    </>
  )
}